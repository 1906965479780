span.bubble {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
span.bubble.hello-bubble{
    width: 48px;
    height: 48px;
    background: #F5F5F5;
}
span.bubble.bubble-single{
    width: 64px;
    height: 64px; 
    border:  thin solid rgba(0, 0, 0, 0.08);
    margin: 0 auto;
}
.div-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}.bg-blured { 
    background: rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(10px);
}.customCard {
    /* border: thin solid rgba(0, 0, 0, 0.15); */
    box-sizing: border-box;
    border-radius: 14px;
    margin-top: 18px;
    padding: 13px 17px; 
    margin-bottom: 23px;
}.challenge--card-warp {
    text-align: center;
}.centered .vkuiAvatar__in{
    margin: 0 auto;
}.challenge--content  .vkuiButton--lvl-primary .vkuiButton__content {
    font-weight: 400;
    letter-spacing: 0.3px;
}.vkuiHorizontalScroll {
    padding-bottom: 13px;
}.challenge--content .challenge--card-warp {
    padding-bottom: 12px;
}.other-friend-invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} .customCard .vkuiSimpleCell{
    /* padding: 0; */
    margin-bottom: 4px;
}span.span.float {
    font-weight: 400;
    color: #9062FF;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
}
.smallRadius .vkuiAvatar__in.Avatar__in{
    border-radius: 14px!important;
}
.customCard.no-border{
    padding: 0;
}
.friends-wrap {
    /* height: 40vh;  */
    overflow-y: auto;
    padding-bottom: 150px;
}
.friends-wrap .vkuiCell__checkbox{
    order: 1;
    margin-right: 0;
}.modal--border_0  .vkuiModalPage__in{
    border-radius: 0;
}.friends--bubble.small {
    padding: 5px 8px 6px;
    background: #F5F5F5;
}.smallRadius.vkuiAvatar {
    border-radius: 12px!important;
}.content-width-100 span.vkuiSimpleCell__children{
    width: 90%;
}