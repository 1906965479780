.testSlideNum{
    width: 33px;
    height: 30px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 4px; 
    color: #818C99; 
    background: #F5F5F5;
}.testingDescrWrap img{
    margin: 16px 0;
    background-size: cover;
    background-position: center center; 
    border-radius: 16px;
    /* height: 184px; */
    width: 100%;
}.bigImage{
    /* height: 245px; */
}.vkuiFixedLayout.testingFixedLayout{
    border-top: thin solid rgb(158 158 158 / 16%);
    /* background: #222225!important; */
    background: var(--background_content)!important; 
}.custom-radio.testing-mode .vkuiRadio__container:before, .custom-radio.testing-mode .vkuiCheckbox__container:before{
    display: none;
}.custom-radio.testing-mode .vkuiRadio__content{
    text-align: left;
    padding: 17px 9px 17px 35px ;    
}label.custom-radio.testing-mode.vkuiTappable--active-background:not([disabled]){
    background: var(--background_highlighted);
}span.alpha__index.float {
    position: absolute;
    left: 15px;
    top: 17px;
    text-transform: lowercase;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1px;
}label.custom-radio.testing-mode.vkuiRadio .vkuiRadio__container, label.custom-radio.testing-mode.vkuiCheckbox .vkuiCheckbox__container{
    background: transparent;
    border-radius: 15px;
    border: thin solid rgb(0 0 0 / 16%);
    box-sizing: none;
    box-sizing: initial;
}label.custom-radio.testing-mode .vkuiRadio__content{
    border: none;
    background: transparent;
}label.custom-radio.testing-mode{
    border-radius: 15px;
    overflow: hidden;
}.testing__slide__wrap{
    /* margin-bottom: 60px; */
    padding-bottom: 70px;
}.testingFixedLayout.disable.vkuiFixedLayout button {
    pointer-events: none!important;
}.testingFixedLayout.disable button{
    opacity: 0.5;
}
.testing__modal__image{
    width: 160px;
    height: 160px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin: 0 auto;
}.theme--space_gray label.custom-radio.testing-mode.vkuiRadio .vkuiRadio__container,.theme--space_gray label.custom-radio.testing-mode.vkuiCheckbox .vkuiCheckbox__container{
    border-color: rgb(255 255 255 / 15%);
}.theme--space_gray label.custom-radio.testing-mode.checked  .vkuiRadio__children {
    color: black;
} .theme--space_gray .testSlideNum{
    background: rgb(245 245 245 / 11%);
}#modal_intest   .vkuiModalPage__in{
    border-radius: 0;
}.vkuiHorizontalCell--s .vkuiHorizontalCell__image {
    padding:0;
}.vkuiHorizontalCell--ios.vkuiHorizontalCell--s:first-child{
    margin-left: 8px;
}.horizontalScroll{
    position: relative;
    overflow: hidden;
}.horizontalScroll--wrapper{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}.testing__slide__wrap img{
    width: 100%!important;
    height: auto!important;
}
.Tappable--active-background:not([disabled]), .Tappable--active {
    /* background: transparent!important; */
  }
#modal_intest
  .vkuiModalPageHeader__content-in {
    font-weight: 600;
}