.progressBar {
    background: #ddd4ff; 
    border-radius: 8px;
    height: 6px;
    width: 100%;
    margin-top: 9px;
    overflow: hidden;
    margin-bottom: 4px;
    position: relative;
}
.theme--space_gray .progressBar {
    background: rgba(161, 122, 255, 0.1);
}
.progressBar-inner {
    background: #8a63f0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
}.progressBar-outer {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    background: #b499ff;
}