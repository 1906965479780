.trainingCardItem img {
    max-width: 100%;
}.trainingCardItem .vkuiCaption--w-regular{
    color: #818C99;
    margin: 1px 0;
}.customCardScroll  .vkuiCard{
    background: transparent;
}
.readed {
    order: 1;
}
.trainingCardItem--Preview{
    width: 100%;
    height: 140px;
    background-position: left center;
    background-size: cover;
    border-radius: 14px;
    position: relative;
    margin-bottom: 7px;
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    background-repeat: no-repeat;
}img.vkLogo-float {
    position: absolute;
    left: 10px;
    bottom: 12px;
    height: 18px;
}.workoutTitle{
    color: white;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 110px;
    padding-left: 8px;
    line-height: 120%;
}.trainingCardItem span.previewCounter{
    font-size: 13px;
    padding: 10px 8px 11px;
    /* margin-left: 8px; */
    line-height: 0;
}.customCardScroll .vkuiSubhead{
    max-height: 44px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}.trainingCardItem  .lockWrap{
    left: auto;
    top: 111px;
    right: 10px;
    opacity: 1!important; 
}.trainingCardItem.closed * {
    opacity: 0.5;
}.trainingCardItem.closed .lockWrap *{
    opacity: 1;
}.trainingCardItem{
    position: relative;
}