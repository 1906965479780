.exerciseExample .vkuiSubhead--w-regular{
    color: #818C99;
    margin-top:2px
}
.exerciseExample{
    padding-top: 6px;
    height: 81px;
    padding-bottom: 6px;
    position: relative;
}.exercisePreviewImage{
    background-size: cover;
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}.lockWrap {
    position: absolute;
    left: 131px;
    background: #363738;
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    border-radius: 50%;
    bottom: 10px;
}.exerciseExample .vkuiHeadline
{
    max-height: 48px;
   overflow: hidden;
   -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
}.exerciseExample.small-one {
    height: auto;
}