.vertical-center {
    min-height: calc(calc(100vh - 300px));
    display: -ms-flexbox;
    padding-top: 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding-bottom: 50px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /* margin-bottom: 50px; */
}
.vertical-center-content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.vertical-center.align-top{
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}.h-100 {
    /* min-height: 100vh; */
}
.text-centered{
    text-align: center;
}
.timeIcon-small{
    margin: 0 auto;
    display: block;
    width: 32px;
    position: absolute;
    padding: 0!important;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 13px;
}.full-width {
    width: calc(100vw - 32px);
    margin: 0 auto;
}.loadbar {
    height: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; 
    left: 0;
    top: 0;
    width: 100%;
    margin: 8px auto 0;
    /* right: 0; */
    height: 11px;
    /* background: white; */
    padding: 4px 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}.loadbar span { 
    height: 100%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 0 2px;
    background: #EBEDF0;
    border-radius: 36px;
}.loadbar span.active{
    background: #19191A;
}.theme--space_gray .loadbar{
    background: #19191a;
}.theme--space_gray .loadbar span.active{
    background: #E1E3E6;
}.theme--space_gray .loadbar span{
    background: #76787A;
}
#onboarding-panel-finish .vkuiPanel__in.Panel__in, #onboarding-panel-finish .vkuiPanelHeader__in.PanelHeader__in{ 
    /* background: #2787F5; */
} .procent {
    font-size: 44px;
    /* color: white; */
    font-weight: 600;
    margin-top: 9px;
}
/* restyle radio */
label.custom-radio .vkuiRadio__icon.Radio__icon {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-right: 1px;
    margin-left: 12px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            display: none;
}.custom-radio 
.vkuiRadio__container:before ,.custom-radio  .vkuiCheckbox__container:before {
    background: -o-linear-gradient(63deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    background: linear-gradient(27deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 11px;
    opacity: 0;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}label.custom-radio .vkuiCheckbox__icon{
    display: none!important;
}
label.custom-radio.vkuiRadio,  label.custom-radio.vkuiCheckbox{
    padding: 0; 
    margin-bottom: 15px;
}
.vkuiRadio__content { 
    z-index: 2;
    padding: 10px 16px;
    width: 100%;
    background: white;
    border-radius: 11px;
    background-clip: padding-box;
    border: 3px solid transparent;
}
label.custom-radio.vkuiRadio .vkuiRadio__container,label.custom-radio.vkuiCheckbox .vkuiCheckbox__container {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 0px -2px 16px rgb(0 0 0 / 6%);
            box-shadow: 0px -2px 16px rgb(0 0 0 / 6%);
    border-radius: 10px;
    /* padding: 10px 16px; */
    text-align: center;
    border: 0.5px solid rgb(0 0 0 / 5%);
    /* filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.08)); */
}label.custom-radio.vkuiTappable--active-background:not([disabled]){
    background: transparent
}
label.custom-radio img{
    vertical-align: middle; 
    margin-right: 12px;
}label.custom-radio.vkuiRadio.with-img { 
    padding: 12px 16px; 
}label.custom-radio .vkuiRadio__children ,label.custom-radio .vkuiCheckbox__content{
    margin: 0!important;
    display: block;
    color: #000;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 17px;
    font-weight: 400;
}label.custom-radio .vkuiCheckbox__content{
    text-align: left;
    z-index: 2;
    padding: 11px 13px 15px;
    background: white;
    border-radius: 10px;
    background-clip: padding-box;
    border: 3px solid transparent;
}
label.custom-radio .vkuiCheckbox__content span{
    font-size: 14px;
    color: #6D7885;
    display: block;
    line-height: 130%;
    margin-top: 4px;
    font-weight: 400;
}
.theme--space_gray label.custom-radio .vkuiRadio__children{
/* color: black; */
}.theme--space_gray
label.custom-radio.vkuiRadio.vkuiTappable--active-background:not([disabled]){
    background: transparent
}
.custom-radio  .vkuiRadio__input:checked~.vkuiRadio__container:before, 
.custom-radio .vkuiCheckbox__input:checked~.vkuiCheckbox__container:before{
   opacity: 1;
}
/* restyle gallery */
.vkuiGallery.custom-gallery{
    width: 100%;
}.custom-gallery .vkuiGallery__slide{
    color: #99A2AD;
    font-size: 24px;
    text-align: center;
    overflow: visible;
}.custom-gallery .vkuiGallery__slide .active{
    font-size: 40px;
    color: black;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}.custom-gallery .vkuiGallery__slide div{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    vertical-align: middle;
    font-weight: 500;
    white-space: nowrap;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}.vkuiProgress__in.Progress__in {
    background: rgb(124, 70, 255);
}.vkuiChipsSelect__options.ChipsSelect__options {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    position: absolute;
    width: 100%;
    max-height: 160px;
    background-color: #fff;
    background-color: var(--modal_card_background);
    -webkit-box-shadow: 0 2px 2px rgb(0 0 0 / 6%), 0 0 2px rgb(0 0 0 / 3%);
            box-shadow: 0 2px 2px rgb(0 0 0 / 6%), 0 0 2px rgb(0 0 0 / 3%);
    border: solid 1px rgba(0,0,0,.12);
    border: solid 1px var(--field_border);
    /* border-top: 0; */
    border-radius: 0 0 8px 8px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
    z-index: 4;
    top: 39px;
}.vkuiChipsSelect__options .vkuiCustomScrollView__box {
    max-height: 160px;
}.vkuiChipsSelect.ChipsSelect.vkuiChipsSelect--sizeY-regular.ChipsSelect--sizeY-regular {
    position: relative;
}.vkuiPanelHeader__content-in{
    font-weight: 500;
}label.custom-radio.small-radion.vkuiRadio {
    -webkit-box-shadow: none;
            box-shadow: none;
    border: none;
    padding: 10px 16px;
    margin-bottom: 0;
}
.vertical-center-content small{
    display: inline-block;
    line-height: 110%;
}
.theme--space_gray
.vkuiButton--lvl-primary{
    /* background: white!important;
    color: black!important; */
}.theme--space_gray .custom-gallery .vkuiGallery__slide .active{
    color: white;
}.theme--space_gray .vkuiFixedLayout.customFixedLayout {
    /* background: #222225!important; */
    background: var(--background_content)!important; 
}.theme--space_gray #onboarding-panel-finish .vkuiPanel__in.Panel__in {
    background: #19191a;
}.theme--space_gray .vkuiProgress{
    background: rgb(91 91 91)!important;
}.CircularProgressbar .CircularProgressbar-text {
    font-weight: 600;
    font-size: 23px;
}.textAnimated {
    height: 70px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
 .textAnimated p.active {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    opacity: 1;
    font-size: 20px;
    top: 0px;
} 
.textAnimated p {
    color: rgb(186 186 186);
    font-size: 18px;
    margin: 8px 0;
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    opacity: 0.4;
    top: -50px;
    position: absolute;
    width: 100%;
    left: 0;
}
.textAnimated p.animate--Up{
    margin-top: 0;
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.4;
    top: 40px;
}.textAnimated p.animate--Down  {
    margin-top: 0;
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.4;
    top: 70px;
}.theme--space_gray label.custom-radio.vkuiRadio .vkuiRadio__container, 
.theme--space_gray .vkuiRadio__content,
.theme--space_gray label.custom-radio.vkuiCheckbox .vkuiCheckbox__container,
.theme--space_gray label.custom-radio .vkuiCheckbox__content,
.theme--space_gray  .offer-radio .vkuiRadio__content{
    background: #2C2D2E;
    background-clip: padding-box;
} .theme--space_gray label.custom-radio .vkuiRadio__children{
    color: white;
}.theme--space_gray label.custom-radio .vkuiCheckbox__content{
    color: white;
}
@media (max-width: 350px) {
    .textAnimated p.active {
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        opacity: 1;
        font-size: 16px;
        top: 0px;
    }
    .textAnimated p{
        font-size: 16px;
    }
}
 