:root {
    --main-bg-color: #6160AD;
  }

.vkuiButton--lvl-primary{
    background: #9062FF; 
    color: white;
}
.vkuiButton--lvl-destructive{
    background: rgba(230, 70, 70, 0.12);
    color: #E64646;
}.theme--space_gray
.vkuiButton--lvl-secondary{
    background: rgba(161, 122, 255, 0.1);
    color: white;
}.theme--space_gray
.vkuiButton--lvl-secondary *{
    color: white;
    fill: white;
}
.theme--space_gray
.vkuiButton--lvl-destructive{
    color: white;
    background: #E64646;
}
.vkuiActionSheet {
    background: white;
    padding: 10px;
    border-radius: 15px 15px 0 0;
}.theme--space_gray .vkuiActionSheet {
    background: #2c2d2e;
}
.vkuiButton--lvl-secondary{
    color: #7C46FF;
    background: rgba(124, 70, 255, 0.1);
}
.vkuiText.text-grey{
    color: #000000;
}.theme--space_gray
.vkuiText.text-grey{
    color: #E1E3E6;
}
img{
    max-width: 100%;
}
.vkuiButton__content{
    text-decoration: none;
}.vkuiButton.disable {
    opacity: 0.4;
    cursor: not-allowed;
}.vkuiPanelHeader{
    z-index: 5;
} 
.vkuiDiv--ios {
    padding: 12px 16px;
}button.vkuiPanelHeaderBack *{
    color: black;
}.theme--space_gray button.vkuiPanelHeaderBack *{
    color: white;
}
.greyText{
    color: #818C99;
} .theme--space_gray
.greyText{
    color: #76787A;
}.vkuiSimpleCell--ios, .vkuiRichCell--ios{
    padding-left: 16px;
    padding-right: 16px;
}.vkuiPopoutWrapper__content
.floatClose{
    top: 5px;
    right: 8px;
}.h-70 {
    /* max-height: 70vh; */
}.scrollWrap {
    overflow: scroll;
}.vkuiRichCell>.vkuiAvatar{
    padding-right: 0;
    margin-right:  18px;
}.customGroup{
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
    padding: 5px 0;
    border-radius: 16px 16px;
    position: relative;
    margin-bottom: 11px;
    background: white;
}.vkuiPopoutWrapper__content .vkuiDiv--ios, .vkuiPopoutWrapper__content .vkuiDiv{
    padding: 6px;
}.vkuiActionSheetItem--ios{
    padding: 0 6px;
}
.view-backgroundImage .vkuiPanel .vkuiPanel__in, .view-backgroundImage .vkuiPanel::after{
    background: url(/src/img/onBoardingBackground.jpg);
    background-size: cover; 
}.theme--space_gray
.view-backgroundImage .vkuiPanel .vkuiPanel__in, .view-backgroundImage .vkuiPanel::after{
    background: var(--background_content); 
}
 .view-backgroundImage {
}
.view-backgroundImage .vkuiPanelHeader__in.PanelHeader__in{
    background: transparent;
}
.vkuiPopoutWrapper__content>*{
    /* pointer-events: visible; */
}.theme--space_gray .vkuiPanelHeader--ios .vkuiPanelHeader__left .vkuiIcon *{
    color: #E1E3E6!important;
    fill: #E1E3E6!important;
}.vkuiRichCell--mult .vkuiRichCell__caption::first-letter {
    text-transform: capitalize;
}.popupContent .vkuiFormItem--ios {
    padding-left: 6px;
    padding-right: 6px;
}.m-t-50{
    margin-top: 43px;
}.theme--space_gray .vkuiPanel .vkuiPanel__in, .vkuiPanel::after {
    background: #0a0a0a;
}
.vkuiText p {
    margin: 0;
}.vkuiHorizontalScroll__in {
    padding-bottom: 10px;
}.vkui__root .react-loading-skeleton{
    background-image: -webkit-gradient( 
        linear,left top, right top,from(#eee),color-stop(#f8f8f8),to(#eee) );
    background-image: -o-linear-gradient( 
        left,#eee,#f8f8f8,#eee );
    background-image: linear-gradient( 
        90deg,#eee,#f8f8f8,#eee );
        -webkit-animation-duration: 0.9s;
                animation-duration: 0.9s;
}
.background-transparent, .theme--space_gray .vkuiFixedLayout.background-transparent{
    /* background: transparent!important;    */
} .theme--space_gray  .vkui__root .react-loading-skeleton {
    background-color: #272727;
    background-image: -webkit-gradient( 
linear,left top, right top,from(#272727),color-stop(#4d4949),to(#272727) );
    background-image: -o-linear-gradient( 
left,#272727,#4d4949,#272727 );
    background-image: linear-gradient( 
90deg,#272727,#4d4949,#272727 );
}.btnFullWidth button.vkuiButton{
    width: 100%!important;
}
.borderTop{
    border-top: thin solid #D7D8D9
} .theme--space_gray 
.borderTop{
    border-color: rgba(255, 255, 255, 0.12);
}
.customGroup.no-padding,.customGroup.no-padding .vkuiGroup__inner{
    padding: 0;
}
.settingsCell .vkuiText{
    font-size: 17;
    font-weight: 400;
}
.vkuiHeadline{
    font-size: 17px;
}

.vkuiActionSheetItem--ios .vkuiActionSheetItem__container {
    padding: 0;
    margin: 13px 0;
}.vkuiActionSheetItem{
    background: transparent!important;
}.vkuiActionSheetItem--android {
    padding: 0 6px; 
}
.vkuiActionSheetItem--android .vkuiActionSheetItem__container{
    padding: 0;
    margin: 11px 0;

}.vkuiFormItem.vkuiFormItem--android {
    padding: 12px 6px;
}.vkuiHeader__content-base, .vkuiHeader__main, .vkuiHeadline{ 
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;            
}

.vkuiPanelHeader--android~.vkuiFixedLayout--top, .vkuiPanelHeader--android~* .vkuiFixedLayout--top:not(.vkuiPanelHeader__fixed) {
    top: calc(56px);
    top: calc(var(--panelheader_height_android) + var(--safe-area-inset-top));
}
.vkuiActionSheet.vkuiActionSheet--android{
    border-radius: 15px;
}
.vkuiButton--sz-l.previewBtn .vkuiButton__in{
    padding-left: 0;
    padding-right: 0;
}
.customCounter .vkuiText, .customCounter .vkuiText *{
      white-space: nowrap;
}
.vkuiFixedLayout--bottom.vkuiFixedLayout--android { 
} 
.vkuiPopoutWrapper__content{
   margin-bottom:  var(--safe-area-inset-bottom);
}.shadow-bottom
.vkuiPanelHeader__fixed{
    box-shadow: 0 2px 17px 0px rgb(0 0 0 / 9%);
}
.vkuiButton--lvl-tertiary {
    background: rgba(0, 0, 0, 0.05);
}.theme--space_gray 
.vkuiButton--lvl-tertiary {
    background: rgb(255 255 255 / 7%);
}
.vkuiPanelHeader__left{
    z-index: 3;
}
.safety-padding-bottom{
    padding-bottom: var(--safe-area-inset-bottom);
}
.flexBox--start{
    justify-content: flex-start;
}
.flexBox--centered{
    align-items: center;
}
.p-b-70{
    padding-bottom: 70px;
}
.vkuiActionSheet--ios {
    width: calc(100% - 20px);
    margin: 10px;
    -webkit-animation: vkui-animation-actionsheet-intro .2s cubic-bezier(.4,0,.2,1);
    animation: vkui-animation-actionsheet-intro .2s cubic-bezier(.4,0,.2,1);
    -webkit-animation: vkui-animation-actionsheet-intro .2s var(--android-easing);
    animation: vkui-animation-actionsheet-intro .2s var(--android-easing);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 12px!important;
    box-shadow: 0 8px 8px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 8%);
}.custom__ActionSheetItem{
    padding: 0;
} .custom__ActionSheetItem  .vkuiActionSheetItem__container{
    margin: 0;
}
.vkuiPopoutWrapper__content .custom__ActionSheetItem.exitIcon.close.floatClose {
    top: 16px;
    right: 16px; 
    height: auto;
    width: auto;
    min-height: auto; 
}.exitIcon .ActionSheetItem__children {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgb(129 140 153 / 13%);
    display: flex;
    align-items: center;
    justify-content: center;
}.vkuiModalPageHeader__content-in{
    font-weight: 600;
}
.vkuiCellCheckbox__icon--on{
    color: var(--main-bg-color);
}
.centered.vkuiAvatar {
    margin: 0 auto;
}
.vkuiText--w-medium {
    font-weight: 400;
}
.flex-collumn{
    display: flex;
    flex-direction: column;
}
.vkuiButton--lvl-overlay_secondary {
    color: #fff;
    color: var(--media_overlay_button_background);
    background-color: #2c2d2e;
    background-color: var(--media_overlay_button_foreground);
} .theme--space_gray 
.vkuiButton--lvl-overlay_secondary {
    background-color: #fff;
    background-color: var(--media_overlay_button_background);
    color: #2c2d2e;
    color: var(--media_overlay_button_foreground);
} .vkuiGroup--plain>.vkuiGroup__separator{
    
}.padding--top--0 .vkuiGroup__inner{
 padding-top: 0;
}.header__with_background
.vkuiPanelHeader__in{
    background: transparent;
}.text--uppercase{
    text-transform: uppercase;
}.letter-spacing{
    letter-spacing: 0.5px;
}.flex-inline{
    display: inline-flex;
    margin: 0;
    align-items: center;
}.customGroup .vkuiHeader,.customGroup .vkuiHeader--ios{
    padding: 0 16px;
} .customGroup   .vkuiGroup__inner>.vkuiHeader:first-child {
    margin-top: -11px;
}.semibold .vkuiText--w-medium{
    font-weight: 500;
}.dangerous--content-box *{
    max-width: 100%;
}#modal_paywall .vkuiModalPage__in{
    border-radius: 0;
}
@media (max-width: 360px) { 
    button.previewBtn .vkuiHeadline {
        font-size: 15px;
    }
}
@media (max-width: 320px) { 
    .vkuiTabs--default .vkuiTabsItem { 
        padding-left: 7px;
        padding-right: 7px;
    }
    .vkuiDiv--android {
        padding: 12px 8px;
    }
    .custom.vkuiMiniInfoCell{
        padding-left: 5px!important;
    }.customGroup{
        padding-left: 4px!important;
        padding-right: 4px!important;
    }.vkuiSimpleCell--android, .vkuiSimpleCell--vkcom {
        padding-left: 8px;
        padding-right: 8px;
    }.trainingWrapBottom { 
        padding: 0 6px 19px; 
    }.vkuiText.custom {
        /* font-size: 13.7px; */
        padding: 0 8px;
    }
}