.trainingRate {
    text-align: center;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
    margin: 0 8px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    /* flex-grow: 1; */
    width: 78px!important;
}
 
.trainingRate:hover,.trainingRate:active{
    /* transform: scale(1.2); */
}.resultItem {
    width: 48%;
    position: relative;
    padding: 3px 0;
}.resultItem p {
    color: #ABABAB;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 12px;
}span.bigOne {
    font-size: 28px;
    display: inline-block;
    font-weight: bold;
}
/* .resultItem:nth-child(odd):after {
    content: '';
    position: absolute;
    right: -27%;
    top: 11px;
    background: #D7D8D9;
    width: 1px;
    height: 60%;
} */
.flexBox.flexBox-around{
    -ms-flex-pack: distribute;
        justify-content: space-around;
}.resultItem .vkuiSubhead{ 
}.textOverflow{
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: block;
    max-width: 79px;
}.textOverflow *{
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}.flexBox.flexBox-flexWrap{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 16px;
}.rateImgWrapInner{
    width: 72px;
    height: 72px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    pointer-events: none;
    background: white;
    border-radius: 12px;
    margin-bottom: 4px;
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    background-clip: padding-box;
    border: 3px solid transparent;
} .trainingRate.active .rateImgWrap:before{
   opacity: 1;
}
.rateImgWrap{
    position: relative;
}
.rateImgWrap:before {
    background: -o-linear-gradient(
63deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    background: linear-gradient(
27deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 11px;
    opacity: 0;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}.theme--space_gray .rateImgWrapInner{
    background: #2C2D2E;
    background-clip: padding-box;
}