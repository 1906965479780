.challenge--header--wrap {
    background-position: center;
    min-height: 177px;
    margin-bottom: 0;
    position: relative;
    background-size: cover;
}.challege--header--title {
    color: white;
    padding: 0;
}.helpIcon.float {
    position: absolute;
    right: 8px;
    top: 4px;
    cursor: pointer;
}.challege--header--title {
    height: 89px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}.challenge--header--wrap.small-header {
    min-height: 150px;
    margin-bottom: -12px;
}.challenge--header--wrap:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
225deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
}.challenge--header--wrap *{
    z-index: 1;
}