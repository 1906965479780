.advBanner{
    height: 132px;
    padding: 0;
}
.adv-inner{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}