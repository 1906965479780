.vkuiText.custom {
    /* font-size: 13.7px; */
    padding: 0 16px;
    margin-bottom: 11px;
    line-height: 130%;
    white-space: pre-line;
}
.custom{
    color: #818C99;
}.flexBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; 
}.customCounter .vkuiText {
    font-size: 12.6px;
    padding-left: 8px;
    font-weight: 400;
    padding-right: 8px;
}.customCounter {
    margin-right: 8px;
    color: white;
    margin-bottom: 7px;
}.flexBox.counterWrap { 
    -webkit-box-pack: start; 
        -ms-flex-pack: start; 
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 0;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}.custom.vkuiMiniInfoCell{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}.custom .vkuiMiniInfoCell__icon {
    width: 10px;
    margin-right: 0;    
}.TrainingDescriptionWrap {
    padding: 0;
}.TrainingDescriptionWrap .vkuiMiniInfoCell__content{
    margin-left: 15px;
    font-size: 14px;
}