.popupContent img {
    max-width: 100%;
}
.videoFrame{
    background-size: cover;
    border-radius: 20px;
}.videoFrame.hidden {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
} .exampleImage{
    position: relative;
    height: 85.8vw;
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
}.exampleImage .floatImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
@media (max-width: 360px) { 
    .exampleImage{
        height: 82.8vw;
    }
}


/* video player */
/* .video-js .vjs-tech {
    position: relative !important;
}
video::-webkit-media-controls-panel-container {
    display: none !important;
}
video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
    display:none !important;
    -webkit-appearance: none;
} 
.video-js .vjs-text-track {
    display: none;
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 0.1em; 
    background-color: #00618e;
    background-color: rgba(0, 97, 142, 1);
  }
  .video-js .vjs-subtitles {
    color: #ffffff  ;
  }
  .video-js .vjs-captions {
    color: #ffffff  ;
  }
  .vjs-tt-cue {
    display: block;
  } */