.AppLoading {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--app_loading_backgorund); }
    @supports not (--css: variables) {
      .AppLoading {
        background-color: #FFFFFF; } }
    .AppLoading .Spinner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .AppLoading .Spinner--ios {
        color: var(--app_loading_spinner_ios); }
        @supports not (--css: variables) {
          .AppLoading .Spinner--ios {
            background-color: #AEB7C2; } }
      .AppLoading .Spinner--android {
        color: var(--app_loading_spinner_android); }
        @supports not (--css: variables) {
          .AppLoading .Spinner--android {
            background-color: #3F8AE0; } }
      .AppLoading .Spinner__self {
        animation: rotator .7s linear infinite;
        transform-origin: center; }
      .AppLoading .Spinner__self svg {
        transform: scale(1); }
  
  @keyframes rotator {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  