.trainingCatalogItemPreview {
    width: 2;
    width: 142px;
    height: 80px;
    background-size: cover;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    background-repeat: no-repeat;
    border-radius: 11px;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
}.exerciseExample .vkuiSimpleCell__description{
    white-space: pre-line;
}.closed .trainingCatalogItemPreview {
    opacity: 0.6;
}.exerciseExample .vkuiSimpleCell__after.SimpleCell__after {
    position: absolute;
    left: 129px;
    top: 57px;
}
.exerciseExample
.lockWrap{
    position: relative;
    left: 0;
    top: 0;
}