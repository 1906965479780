.timer--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    color: #9062FF; 
    padding: 2px 6px;
}.timer--clock--wrap span {
    border: thin solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 6px;
    width: 28px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    font-size: 18px;
    font-weight: 600;
}.timer--text {
    color: #818C99;
    text-align: center;
    font-size: 13px;
    margin-top: 3px;
}.timer--separator {
    font-weight: 700;
    font-size: 24px;
}.timer--wrapper.theme__challengeBanner{
    margin-left: -2px;
    width: 207px;
}.timer--wrapper.theme__challengeBanner
.timer--separator{
    font-size: 18px;
}
.theme__challengeBanner .timer--text, .timer--wrapper.theme__challengeBanner{
    color: black;
} 
.theme__challengeBanner.dark--theme .timer--text, .timer--wrapper.theme__challengeBanner.dark--theme{
    color: white
}.theme__challengeBanner  .timer--clock--wrap span{
    width: 20px;
    height: 28px;
    margin: 0 2px;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.08);
    border: none;
}.theme__challengeBanner.dark--theme  .timer--clock--wrap span{
    background: rgba(255, 255, 255, 0.3);
}.theme__challengeBanner
.timer--text{
    font-size: 12px;
    margin-top: 1px;
}