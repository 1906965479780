

.theme--space_gray .customGroup, .theme--space_gray .vkuiPanelHeader__in, .theme--space_gray .vkuiPanelHeader--sizeX-compact .vkuiPanelHeader__separator{
    background: #19191a;
}
.vkuiPanelHeader + .customGroup, .Tabs + .customGroup,  .m-t-50 .customGroup:nth-child(1), .customFixedLayout +  .customGroup{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-shadow: 0px 2px 3px -2px rgb(0 0 0 / 8%), 0px 6px 20px rgb(0 0 0 / 8%);
            box-shadow: 0px 2px 3px -2px rgb(0 0 0 / 8%), 0px 6px 20px rgb(0 0 0 / 8%);
}.popupContent {
    max-height: 61vh;
    overflow: scroll;
}.ActionSheetItem__children{
    width: 100%;
}.p-b-60 .vkuiPanel__in {
    padding-bottom:  var(--safe-area-inset-bottom);
}.theme--space_gray .vkuiFixedLayout--bottom{
    /* background: #19191a; */
    background: var(--background_content)!important; 
}.theme--space_gray .customFixedLayoutItem.active{
    color: white;
}flexBox.flexStart{
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}.flexBox.flexContentCenter{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}