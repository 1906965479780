.modalOfferForm .vkuiFormLayout__container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 10px 0 0 0;
}.modalOfferForm.scrollable{
    overflow: scroll; 
}.modalOfferForm .vkuiFormLayout__container{
    /* width: 480px; */
}
.scrollable
label.custom-radio.offer-radio.vkuiRadio{
    width: 111px;
    margin-right: 8px;
}
label.custom-radio.offer-radio.vkuiRadio {
    width: 32%;
}label.custom-radio.vkuiRadio.offer-radio .vkuiRadio__container{
    padding: 0;
}.offer-radio-content {
    text-align: center;
    height: 153px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}label.custom-radio .vkuiRadio__content  span.offer-label {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -12px;
    z-index: 3;
    background: -o-linear-gradient( 
63deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    background: linear-gradient( 
27deg, #fc8b9c, #ce05fc,#3f7cfc, #e35cc6);
    border-radius: 60px;
    color: white;
    font-weight: 500;
    font-size: 11px!important;
    letter-spacing: 1px;
    padding: 0 0;
    width: 93%;
}span.close {
    background: #ebecee;
    width: 24px;
    height: 24px;
    pointer-events: visible!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    /* float: right; */
}.theme--space_gray span.close{
    background: #272729;color: #909499;
}
.offer-radio .vkuiRadio__content {
    padding: 0;
}span.checkWrap {
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    background: red;
    border-radius: 50%;
    margin-right: 10px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #9062FF
}.flexBox.flexStart{
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}.vkuiCheckbox.thumbler{
    padding: 0;
}.thumbler .vkuiCheckbox__content{
    font-weight: 500;
}label.custom-thumbler {
    position: relative;
}input.thumbler-input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
} 
span.thumbler-content {
    background: rgb(224 224 224);
    display: block;
    width: 51px;
    height: 31px;
    border-radius: 100px;
    -webkit-transition: 0.1s all;
    -o-transition: 0.1s all;
    transition: 0.1s all;
}span.thumbler-content:after {
    content: '';
    position: absolute; 
    left: 2px;
    top: 2px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 3px 8px rgb(0 0 0 / 15%);
            box-shadow: 0px 3px 8px rgb(0 0 0 / 15%);
    width: 27px;
    height: 27px;
    border-radius: 50%;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}input.thumbler-input:checked + span.thumbler-content{
    background: #9062FF;
}input.thumbler-input:checked + span.thumbler-content:after {
    left: 22px; 
}.theme--space_gray span.thumbler-content {
    background: #cccccc;
}
.hide{
    opacity: 0;
}span.panelHeaderCloseText {
    padding-left: 10px;
}.payWallHeaderWrap{
    padding-left: 8%;
}
.offer-radio
.vkuiRadio__content {
    z-index: 2;
    padding: 0px 5px;
    width: 100%;
    background: white;
    border-radius: 14px;
    background-clip: padding-box;
    border: 3px solid transparent;
}
.custom-radio.offer-radio .vkuiRadio__container:before{
    border-radius: 14px;
}label.custom-radio.offer-radio.vkuiRadio .vkuiRadio__container{
    border-radius: 14px;
    
}.offer-radio-content .customCounter .vkuiText{
    font-size: 11px!important;
    padding-left: 4px;
    font-weight: 400;
    padding-right: 4px;
    line-height: 40%;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.vkuiModalPageHeader__content-in{
    /* font-weight: 700; */
}
@media (max-width: 360px) {
    .payWallHeaderWrap { 
        padding-left: 0; 
    }label.custom-radio.offer-radio.vkuiRadio {
        width: 33%;
    } label.custom-radio .vkuiRadio__content span.offer-label { 
        width: 98%;
    }.offer-radio-content .customCounter .vkuiText{
        letter-spacing: 0; 
    }
}
@media (max-width: 340px) {
    label.custom-radio .vkuiRadio__content span.offer-label {
        width: 100%;
        font-size: 8px;
    }.vkuiCaption--l-1 {
        font-size: 10px; 
    }
    .offer-radio-content .customCounter .vkuiText {
        font-size: 9px;
    }
}