.header--fadeHeader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(48px  + var(--safe-area-inset-top));
    background:linear-gradient(
        180deg, white 20%, rgb(255 255 255 / 0%));
    z-index: 2;
}.theme--space_gray  .header--fadeHeader { 
    background: linear-gradient(
180deg,  var(--background_content) 20%, rgb(0 0 0 / 0%)); 
/* background: transparent; */
}