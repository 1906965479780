.trainingImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover; 
    /* border-radius: 20px; */
}.trainingImageWrap {
    position: relative;
    height: 329px;
    margin: 0 0px;
    position: relative;
    /* width: 100%; */
    border-radius: 20px;
    overflow: hidden;
}.trainingImageShadow{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: -o-linear-gradient(
269.8deg
, rgba(0, 0, 0, 0) 0.17%, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.7) 75.57%);
    background: linear-gradient(
180.2deg
, rgba(0, 0, 0, 0) 0.17%, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.7) 75.57%);
}
.counterBox.flexBox {
    position: absolute;
    left: 3px;
    top: 13px;
    width: 100%;
    margin-top: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 8px;     
    -webkit-box-sizing: border-box;     
            box-sizing: border-box;
}span.previewCounter {
    background: rgb(0 0 0 / 28%);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-radius: 14px;
    font-weight: 500;
    margin-right: 8px;
    font-size: 14px;
    padding: 5px 10px;
    /* margin-left: 8px; */
    line-height: 16px;
    color: white;
    display: block;
}.trainingWrapBottom {
    position: absolute;
    bottom: 0; 
    left: 0;
    padding: 0 15px;
    right: 0;
    padding-bottom: 19px;
}.fullView{
    padding: 0!important;
    margin-bottom: 12px;
}.fullView .trainingImageWrap{
    border-radius: 0;
    height: 100vw;
}img.float.vkTrainingsLogo {
    position: absolute;
    left: 16px;
    bottom: 16px;
}.sharingFrame{
    padding: 16px;
}.trainingImageWrap.sharingFrame {
    background: rgba(0, 0, 0, 0.25);
}.sharingFrame .counterBox{
    /* display: none; */
}.changeWorkout.vkuiButton--sz-l   .vkuiButton__content{
    padding-top: 2px;
    padding-bottom: 0px;
    -webkit-transform: rotate(
40deg);
        -ms-transform: rotate(
40deg);
            transform: rotate(
40deg);
}.trainingImageWrap.sharingFrame .trainingImageShadow{
    background: rgb(0 0 0 / 25%);
}

img.trainingImage--inner {
    position: absolute;
    left: -4vw;
    top: 0px;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 111vw;
    height: 112vw;
    /* height: auto; */
    max-width: initial;
}

@media (max-width: 350px) {
    .previewBtn { 
        padding-left: 7px;
        padding-right: 7px;
    }
}