.challengeBanner{ 
    padding: 0;
    background-size: cover!important; 
    height: 130px; 
} 
.challengeBanner.challengeOpen{
    height: 168px;
}
.friends--bubble{ 
    background: rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 7px 8px 9px;
    display: inline-block;
    margin-top: 12px;
}
.dark--theme  .friends--bubble{
    background: rgba(255, 255, 255, 0.2);
    color: white;
}
.dark--theme .vkuiTitle,.dark--theme .vkuiCaption {
    color: white;
}.challengeStarted{
    order: -1;
}.customGroup.challengeStarted {
    padding: 0;
    border-radius: 0 0 16px 16px;
}.challengePreviewWrap {
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 12px;
    margin-bottom: 19px;
    box-sizing: border-box;
}.dark--theme .challengePreviewWrap{
    color: white;
}