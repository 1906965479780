.profileHeader{
    position: relative;
}.profileHeader
.vkuiRichCell__in{
    overflow: visible;
}.settingsButton{
    border: 1px solid #F2F3F5;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.03);
            box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.03);
    background: white;
    color: black;
    font-weight: 100;
}.vkuiPanel .vkuiPanel__in, .vkuiPanel::after{
    /* background: #f2f3f5; */
}.groupIcon-abs{
    position: absolute;
    right: 12px;
    top: 11px;
}.settingsCell.vkuiRichCell {
    min-height: 52px;
}.settingsCell .vkuiRichCell__in{
    /* border-bottom: thin solid #E1E3E6;  */
}.settingsCell:nth-last-child(1) .vkuiRichCell__in{
    border-bottom: none; 
}.statisticsHeader .vkuiHeader__main { 
    padding-right: 19px;
}.resultItem .vkuiCaption {
    color: #818C99;
}.theme--space_gray .resultItem .vkuiCaption {
    color: #76787A;
} span.bigOne{
    font-size: 24px;
    font-weight: 500;
    color: black;
}.theme--space_gray span.bigOne{
    color: #E1E3E6;
}.theme--space_gray  .settingsButton {

} .theme--space_gray .settingsCell .vkuiRichCell__in{
    border-color: #646464; 
}img.floatLevelImg { 
    position: absolute;
    left: -65px;
    z-index: 1;
    top: 56px; 
}.floatLevelImg {
    position: absolute;
    left: -59px;
    background: white;
    z-index: 1;
    top: 72px;
    width: 36px;
    height: 36px;
    border: 3px solid #9062ff;
    border-radius: 50%;
} .theme--space_gray .floatLevelImg{
    background: #262231;
}.floatLevelImg *{
    max-width: 100%;
    max-height: 100%;
}
.profileHeader .vkuiRichCell__caption{
    color: #9062FF;
    font-size: 16px;
    font-weight: 500;
    margin-top: 9px;
}.vkuiActionSheetItem--ios::after{
    display: none;
}.custom-radio-inline .vkuiRadio__content{
    padding: 0;
}.custom-radio-inline .vkuiRadio__container,.custom-radio-inline .vkuiCheckbox__container{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}.custom-radio-inline.vkuiRadio__icon{

}.custom-radio-inline .vkuiRadio__input:checked~ .vkuiRadio__container .vkuiRadio__icon{
    border-color: #9062FF;
    margin-right: 0;
}.custom-radio-inline .vkuiRadio__input:checked~.vkuiRadio__container .vkuiRadio__icon::after{
    -webkit-transform: scale(1.4);
        -ms-transform: scale(1.4);
            transform: scale(1.4);
    background-color: #9062FF;
}.custom-radio-inline .vkuiRadio__content{
    background: transparent;
}.custom-radio-inline .Icon--check_box_on_24{
    color: #9062FF;
}.avatarOnTrack .vkuiAvatar__in.Avatar__in {
    position: absolute;
    left: 22.4vw;
    width: 11vw!important;
    height: 11vw!important;
    top: 64.8vw;
    background: #9062ff;
    border: 3px solid #9062ff;
}.custom-radio-inline .vkuiRadio__icon,.custom-radio-inline .vkuiCheckbox__icon{
    margin-right: 0;
}.floatRight {
    position: absolute;
    right: 20px;
    top: 30%;
}.vkuiPopoutWrapper__content .vkuiCheckbox--ios {
    padding: 0 6px;
}label.checkbox-label {
    position: relative;
    padding: 11px 6px;
    display: block;
    padding-right: 35px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
}.checkOn{
    display: none;
}
label.checkbox-label .vkuiIcon {
    position: absolute;
    right: 7px;
    top: 0;
    bottom: 0; 
    margin: auto;
}label.checkbox-label input:checked + span .checkOn{
    display: block;
}label.checkbox-label input:checked + span .checkOff{
    display: none;
}label.checkbox-label input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
    margin: 0;
    opacity: 0;
    z-index: 1;
}.profileHeader .vkuiAvatar__in.Avatar__in{
    border: 4px solid #8f61fe;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    background-color: rgba(138, 99, 241, 0.5);
}.trackItem.flexBox:nth-child(odd) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    text-align: right;
}

.trackItem.flexBox {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    height: 130px;
    position: relative;
    margin: 0;
}.trackItemImg img {
    max-width: 100%;
    max-height: 98%;
}.trackItemImg {
    width: 74px;
    height: 74px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    z-index: 1;
    background: white;
    border-radius: 50%;
    border: 8px solid transparent;
    -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 88px rgba(0, 0, 0, 0.16);
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 88px rgba(0, 0, 0, 0.16);
    border: 8px solid #9062FF;
    margin-right: 12px;
}.trackItem.flexBox:nth-child(odd) .trackItemImg {
    margin-left: 12px;
    margin-right: 0;
}.trackItem.flexBox.disabled .trackItemImg{
    border: none;
    margin-left: 12px; 
}.trackItem.disabled .trackItemImg img{
    opacity: 0.5;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}


.trackItem.flexBox:after {
    content: '';
    position: absolute;
    left: 45px;
    top: 0;
    right: 0;
    width: calc(100% - 90px);
    height: 119px;
    background: transparent;
    border-radius: 130px;
    border: 8px solid #eff0f2;
    border-width: 8px;
    clip: rect(70px,552px,200px,66px);
}

.trackItem.flexBox:before {
    content: '';
    top: calc(100% - 3px);
    position: absolute;
    left: 45px; 
    right: 0;
    width: calc(100% - 90px);
    height: 119px;
    background: transparent;
    border-radius: 130px;
    border: 8px solid #eff0f2;
    border-width: 8px;
    clip: rect(0px,67px,70px,0);
}.trackItem.flexBox:nth-child(even)::after, .trackItem.flexBox:nth-child(even)::before {
    -webkit-transform: scale(-1,1);
        -ms-transform: scale(-1,1);
            transform: scale(-1,1);
}.trackItem.flexBox.track-active:after,.trackItem.flexBox.track-active:before{
    border-color: #9062FF!important;
}.trackItem.flexBox:nth-last-child(1)::after,.trackItem.flexBox:nth-last-child(1)::before{
    display: none;
}
.theme--space_gray .trackItem.flexBox:after, .theme--space_gray .trackItem.flexBox:before{
    border-color: #9aa2ad;
}.theme--space_gray .trackItem.disabled  .trackItemImg{
    background: grey;
}.theme--space_gray
.profileHeader .vkuiAvatar__in.Avatar__in{
    border-color: transparent;
}.trackWrap,.vkuiDiv--ios .trackWrap{
    position: relative;
    padding: 12px 7px 12px;
    marginTop: 7px;
    margin-bottom:  90px;
    padding-bottom:  var(--safe-area-inset-bottom);
}
.vkuiPanel--android .trackWrap{
    padding-bottom: 110px;
    margin-bottom: 0;
}