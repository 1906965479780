.customCell .vkuiRichCell__children {
    /* font-size: 12px; */
    font-weight: 400;
}.customCell.vkuiRichCell{
    min-height: 48px;
}
.customCell.vkuiRichCell .vkuiRichCell__in{

}.customCell .vkuiRichCell__children{
    /* height: 39px; */
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}.floatClose{
    position: absolute;
    right: 16px;
    top: 16px;
}
.articleWrap img{
    width: 100%!important;
    height: auto!important;
}
.articleWrap ul{
    padding-left: 16px;
}
.articleWrap h3,.articleWrap h1,.articleWrap h2{
    font-weight: 600; 
    color: black;
}.theme--space_gray .articleWrap h3,.theme--space_gray .articleWrap h2,.theme--space_gray .articleWrap h1{
    color: white;
}.articleWrap h2{
    font-size: 20px;
}.articleWrap h3{
    font-size: 17px;
}
.articleWrap h3 *,.articleWrap h1 *,.articleWrap h2 *{
    font-weight: 600;
}
.articleWrap *{
    max-width: 100%;
}
.articleWrap iframe,.articleWrap video{
   width: 100%!important;
   height: 180px!important;
}
 
.articleWrap li::marker { 
    color: #9062FF; /* or whatever color you prefer */
  }.theme--space_gray
  .articleWrap li::marker { 
    color: #A17AFF; /* or whatever color you prefer */
  }

@media (max-width: 320px) {
    .trener-popout .vkuiText {
        font-size: 13px;
        line-height: 121%;
    } .trener-popout .vkuiTitle--l-2 {
        font-size: 18px;
        line-height: 100%;
    }
}