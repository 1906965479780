
.customFixedLayout {
    background: white;
    -webkit-backdrop-filter: blur(37px);
    backdrop-filter: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    /* border-top: thin solid rgba(0, 0, 0, 0.12); */
}
.customFixedLayoutWrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 9px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}.customFixedLayoutItem {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-decoration: none;
    margin: 0 0%;
    font-size: 13px;
    color: #99A2AD;
}.customFixedLayoutItem.active{
    color: black;
}.vkuiTabs--default .vkuiTabsItem--selected::after{
    background: #9062FF;
}
.loading, .loading *{
    pointer-events: none!important;
}.loading.vkuiTabsItem--selected::after{
    opacity: 0;
}